import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Project from "../components/Project"
import SEO from "../components/SEO";

const Projects = ({
    data,
    location
}) => {
    const {
        allProjectsJson: { edges: projects = [] } = {},
        allCloudinaryAsset: { edges: images = [] } = {}
    } = data;
    const headerChildren = <Fragment>
        <h1>Things I've Done</h1>
        <div className="header-meta text-secondary mt-2">
            <div>A small collection of some of my most recent work</div>
        </div>
    </Fragment>;

    return (
        <Layout
            headerChildren={headerChildren}
            path={location.pathname}
        >
            <SEO
                title="Projects"
                pathname="/projects"
                description="Check out a collection of my work!"
            />
            <Section>
                <div className="max-w-screen-sm mx-auto">
                    <h2 className="heading text-center mb-4">About My Work</h2>
                    <div
                        className="text-secondary text-center mb-2"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                            When I scope a new project, I think of a new skill or practice I want to learn and find a way to integrate it into the project's core functionality.
                            Over time, this has allowed me to build a skill set that includes many of the important concepts of web development spanning the full stack.
                    </div>
                </div>
            </Section>
            <Section
                fluid
                margin="my-4 md:mt-0 mb-16"
            >
                {projects.map(({ node: project }, index) => {
                    const image = images.find(({ node: { fluid } }) => fluid.src.includes(project.imageName));

                    let waveBackgroundColor = "#FFF";

                    if(projects[index + 1]){
                        waveBackgroundColor = projects[index + 1].node.backgroundColor;
                    }

                    return (
                        <Project
                            key={project.id}
                            project={project}
                            waveTop={{
                                display: index === 0 ? true : false,
                                style: index,
                                backgroundColor: waveBackgroundColor,
                                fillColor: project.backgroundColor,
                                isTop: true
                            }}
                            waveBottom={{
                                display: true,
                                style: index,
                                backgroundColor: waveBackgroundColor,
                                fillColor: project.backgroundColor,
                                isTop: false
                            }}
                            image={image.node.fluid}
                            imageOrder={index % 2 === 0 ? "first" : "last"}
                        />
                    );
                })}
            </Section>
        </Layout>
    );
};

export const indexQuery = graphql`
    query {
        allProjectsJson {
            edges {
                node {
                    id
                    backgroundColor
                    linkBorderColor
                    shadowColor
                    description
                    imageName
                    link
                    name
                    technologies
                }
            }
        },
        allCloudinaryAsset {
            edges {
                node {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
        }
    }
`;

export default Projects;